<template>
    <div style="height: .4rem" class="HeadBox">
        <div :style="headStyle" :class="{
        'headContent':backType=='gra',
        'headContent2':backType=='blank',
        'headContent3':backType=='normal'||!backType,
        'headContent4':backType=='white',
        }">
            <Svgs v-if="!($route.name=='orderList' && event.dataCode) && back && !device"
                  :style="backStyle"
                  @click.native="backPath"
                  class="backBtn"
                  :color="svgColor||setSvgColor"
                  name="iconfanhui"></Svgs>
            <span v-if="device && back" @click="backPath" class="backBtn">
                返回
            </span>
            <slot name="headText"></slot>
            <div class="rightBtn">
                <slot name="rightBtn"></slot>
            </div>
        </div>
    </div>
</template>

<script>

    import { mapGetters, mapMutations } from "vuex";
    export default {
        name: "Header",
        props: [
            'back',//是否有后退按钮
            'backEvent', //自定义后退按钮事件
            'backType',//头部类型gra(配合渐变色) blank(白底黑字) normal或不填(正常填充色白字) white (白背景)
            'backStyle',//自定义按钮样式
            'headStyle',//自定义头部样式
            'svgColor',//特殊指定后退颜色
        ],
        data(){
            return {
                val: '',
                tag: '',
                show: false
            }
        },
        created() {
        },
        computed: {
            ...mapGetters(["event"]),
            device(){
                var ua = navigator.userAgent.toLowerCase();
                var isWeixin = ua.indexOf("micromessenger") != -1;
                if (isWeixin) {
                    return true;
                } else {
                    return false;
                }
            }
        },
        methods: {
            backPath() {
                if (this.backEvent) {
                    this.$emit('backEvent');
                } else {
                    this.$router.go(-1);
                }
            },
            setSvgColor() {
                let color = '#000';
                if (this.backType == 'blank' || this.backType == 'white'|| this.backType == 'gra') {
                    color = '#fff';
                }
                return color;
            }
        }
    }
</script>

<style lang="scss">
    .HeadBox{
        .headContent, .headContent2, .headContent3, .headContent4 {
                height: .4rem;
                line-height: .4rem;
                font-size: .2rem;
                position: fixed;
                left: 0;
                top: 0;
                width: 100%;
                z-index: 100;
                font-weight: 500;
            }

            .headContent {
                background: var(--gradualColor);
                color: #fff;
            }

            .headContent2 {
                background: rgba(0, 0, 0, 0);
                color: #333;
                font-weight: 500;
            }

            .headContent3 {
                background: var(--themeColor);
                color: #fff;
            }

            .headContent4 {
                background: #fff;
                color: #333;
            }

            .backBtn {
                position: absolute;
                left: .15rem;
                top: .03rem;
                white-space: nowrap;
            }

            .rightBtn {
                position: absolute;
                right: .25rem;
                top: .03rem;
                height: .4rem;
                width: 3rem;
                text-align: right;
            }

            .rightBtn div {
                float: right;
            }
    }

    
</style>